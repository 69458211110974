const copy = {
  alyxEvents: {
    panels: [
      {
        time: "OCT 20 - 11:00 AM",
        titleText: "SUSTAINABILITY:",
        additionalText: [
          "IN CONVERSATION WITH AMF, MAJOCCHI,",
          "VIBRAM, ECCO LEATHER + MATTHEW WILLIAMS",
          "MODERATED BY JO-ANN FURNISS",
        ],
        link:
          "https://www.eventbrite.com/e/hxouse-alyx-sustainability-in-fashion-panel-tickets-76176067669",
      },
      {
        time: "OCT 20 - 12:10 PM",
        titleText: "IN CONVERSATION WITH NICK KNIGHT (VIA SKYPE)",
        additionalText: ["+ MATTHEW WILLIAMS", "MODERATED BY JO-ANN FURNISS"],
        link:
          "https://www.eventbrite.com/e/in-conversation-with-nick-knight-matthew-williams-tickets-76176689529",
      },
      {
        time: "OCT 20 - 3:15 PM",
        titleText: "ALYX + OK-RM PANEL",
        additionalText: [],
        link:
          "https://www.eventbrite.com/e/alyx-ok-rm-panel-tickets-76177505971",
      },
      {
        time: "OCT 21 - 11:00 AM",
        titleText: "IN CONVERSATION WITH MATTHEW WILLIAMS,",
        additionalText: [
          "FRASER AVERY FROM STÜSSY,",
          "ADAM COATES FROM PRAIRIE RECORDS",
          "+ LA MAR C. TAYLOR",
          "MODERATED BY AVI GOLD",
        ],
        link:
          "https://www.eventbrite.com/e/in-conversation-with-matthew-williams-stussy-adam-coates-from-prarie-records-la-mar-c-taylor-tickets-76178535049",
      },
      {
        time: "OCT 21 - 3:15 PM",
        titleText: "IN CONVERSATION WITH BLONDEY MCCOY",
        additionalText: ["+ MATTHEW WILLIAMS", "MODERATED BY JO-ANN FURNISS"],
        link:
          "https://www.eventbrite.com/e/in-conversation-with-blondey-mccoy-matthew-williams-tickets-76180185987",
      },
    ],
    workshops: [
      {
        time: "OCT 20 - 1:10 PM",
        titleText: "SHOWSTUDIO WORKSHOP",
        link:
          "https://www.eventbrite.com/e/hxouse-x-showstudio-workshop-tickets-76177439773",
      },
      {
        time: "OCT 20 - 4:15 PM",
        titleText: "ALYX + OK-RM WORKSHOP",
        link:
          "https://www.eventbrite.com/e/alyx-ok-rm-workshop-tickets-76177838967",
      },
      {
        time: "OCT 20 - 5:15 PM",
        titleText: "ALYX + VIBRAM WORKSHOP",
        link:
          "https://www.eventbrite.com/e/hxouse-alyx-vibram-workshop-tickets-76179193017",
      },
      {
        time: "OCT 21 - 12:45 PM",
        titleText: "ECCO LEATHER WORKSHOP",
        link:
          "https://www.eventbrite.com/e/hxouse-alyx-ecco-leather-tickets-76179189005",
      },
      {
        time: "OCT 21 - 2:45 PM",
        titleText: "MAJOCCHI WORKSHOP",
        link:
          "https://www.eventbrite.com/e/hxouse-alyx-majocchi-tickets-76179586193",
      },
      {
        time: "OCT 21 - 3:15 PM",
        titleText: "AMF WORKSHOP",
        link:
          "https://www.eventbrite.com/e/hxouse-amf-workshop-tickets-76179636343",
      },
    ],
  },
};

export default copy;
