import React, { useEffect } from "react";
import styled from "styled-components";
import { TweenMax } from "gsap/TweenMax";

import "src/fonts.css";

import useWindowSize from "src/hooks/useWindowSize";

import copy from "src/copy";

import BackgroundImg from "src/images/background.jpg";
import BuckleImg from "src/images/buckle.jpg";
import HXOUSETypeface from "src/images/hxouse.png";
import HXOUSEFooterImg from "src/images/hxouse-small.png";
import AlyxFooterImg from "src/images/alyx-small.png";
import AlyxTypefaceImg from "src/images/alyx-type.png";

import SEO from "src/components/seo";
import GlobalStyle from "src/components/globalStyle";

const mediaQueries = {
  desktop: 800,
  desktopSmall: 750,
  tablet: 950,
  tabletLandscape: 1200,
  smallPhones: 375,
  smallestPhones: 320,
};

const BounceBackground = styled.div`
  background-image: url(${BackgroundImg});
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overscroll-behavior-y: none;
  z-index: 10;

  @media (max-height: 700px) and (min-width: ${mediaQueries.tablet}px) {
    position: relative;
  }
`;

const ContentContainer = styled.div`
  color: #898989;
  opacity: 0;
  display: grid;
  grid-template-rows: 10% 80% 10%;
  grid-template-areas:
    "topbar"
    "content"
    "footer";
  min-height: 100%;

  @media (max-width: ${mediaQueries.tablet}px) {
    grid-template-rows: auto;
  }
`;

const TopBar = styled.div`
  grid-area: topbar;
  padding: 20px 30px;

  @media (max-height: ${mediaQueries.desktop}px) {
    padding: 15px 20px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    justify-self: center;
    padding: 15px;
  }
`;

const BackLink = styled.a`
  font-family: "Neue Haas Grotesk";
  text-transform: uppercase;
  color: #898989;
  transition: 0.25s opacity ease-in-out;
  font-size: 14px;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
  align-self: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "panel main workshop";
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: ${mediaQueries.tablet}px) {
    grid-template-areas:
      "main"
      "panel"
      "workshop";
    grid-template-columns: 1fr;
  }
`;

const PanelWrapper = styled.div`
  grid-area: panel;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;

  @media (max-width: ${mediaQueries.tablet}px) {
    margin-top: 70px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    margin-top: 45px;
  }

  /* targets iPhone + devices */
  @media (max-height: 628px) and (max-width: 414px) {
    margin-top: 40px;
  }

  /* targets iPhone X devices */
  @media (min-height: 630px) and (max-width: 375px) {
    margin-top: 40px;
  }

  /* targets iPhone 6 devices */
  @media (max-height: 560px) and (max-width: 375px) {
    margin-top: 40px;
  }
`;

const Events = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    width: 85%;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    width: 85%;
  }
`;

const EventContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${props => (props.eventType === "panel" ? "30px" : "40px")};

  @media (max-height: ${mediaQueries.desktopSmall}px) {
    margin-bottom: ${props => (props.eventType === "panel" ? "20px" : "30px")};
  }

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    margin-bottom: ${props => (props.eventType === "panel" ? "30px" : "30px")};
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    margin-bottom: 30px;
  }
`;

const EventTitleRSVP = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
`;

const EventTitleDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const EventText = styled.p`
  font-family: "Neue Haas Grotesk";
  text-transform: uppercase;
  font-size: 12px;
  margin: 0 0 4px 0;
  @media (max-height: ${mediaQueries.desktopSmall}px) {
    margin: 0 0 5px 0;
  }
  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    font-size: 11px;
  }
`;

const EventButton = styled.a`
  font-family: "Neue Haas Grotesk";
  /* text-decoration: none; */
  color: #898989;
  text-transform: uppercase;
  font-size: 12px;
  border: 1px solid #898989;
  border-radius: 50%;
  background: transparent;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 28px;
  justify-self: end;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s opacity ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    font-size: 11px;
    width: 45px;
  }

  @media (max-height: ${mediaQueries.desktopSmall}px) {
    height: 24px;
  }
`;

const Subheader = styled.h3`
  font-family: "Neue Haas Grotesk";
  font-size: 22px;
  text-transform: uppercase;
  width: 210px;
  text-align: center;
  line-height: 1.2;
  margin: 0 0 40px 0;

  @media (max-height: ${mediaQueries.desktop}px) {
    font-size: 18px;
    width: 170px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 32px;
    width: 215px;
  }
`;

const MainWrapper = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mediaQueries.tablet}px) {
    margin: 70px 0;
    justify-content: center;
  }

  /* targets iPhone + devices */
  @media (max-height: 628px) and (max-width: 414px) {
    margin: 40px 0;
  }

  /* targets iPhone X devices */
  @media (max-height: 630px) and (max-width: 375px) {
    margin: 40px 0;
  }

  /* targets iPhone 6 devices */
  @media (max-height: 560px) and (max-width: 375px) {
    margin: 8px 0;
  }
`;

const Header = styled.h1`
  font-family: "AFT", sans-serif;
  font-size: 45px;
  text-align: center;
  text-transform: uppercase;

  @media (max-height: ${mediaQueries.desktop}px) {
    font-size: 42px;
  }

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    font-size: 32px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 32px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    font-size: 24px;
  }
`;

const PresentedWrapper = styled.div`
  margin: 40px 0 0 0;
  text-align: center;

  @media (max-height: ${mediaQueries.desktop}px) {
    margin: 30px 0 0 0;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    margin: 30px 0 0 0;
  }

  @media (max-width: ${mediaQueries.smallPhones}px) {
    margin: 20px 0 0 0;
  }
`;

const PresentedByText = styled.p`
  font-size: 16px;
  font-family: "Neue Haas Grotesk";
  text-transform: uppercase;
  margin-bottom: 2px;

  @media (max-height: ${mediaQueries.desktop}px) {
    font-size: 14px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 12px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    font-size: 10px;
  }
`;

const HXOUSEImg = styled.img`
  width: 125px;

  @media (max-height: ${mediaQueries.desktop}px) {
    width: 110px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    width: 100px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    width: 80px;
  }
`;

const BuckleWrapper = styled.div`
  margin-top: 30px;
  width: 400px;

  @media (max-height: ${mediaQueries.desktop}px) {
    width: 350px;
  }

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    width: 300px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    width: 275px;
    margin-top: 20px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    width: 200px;
  }
`;

const Buckle = styled.img`
  width: 100%;
  transform: perspective(2000px);
`;

const FootnoteWrapper = styled.div`
  margin-top: 40px;

  @media (max-width: ${mediaQueries.smallPhones}px) {
    margin-top: 30px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    margin-top: 20px;
  }
`;

const FootnoteImg = styled.img`
  width: 150px;

  @media (max-height: ${mediaQueries.desktop}px) {
    width: 130px;
  }
`;

const Date = styled.p`
  margin-top: 50px;
  font-family: "AFT", sans-serif;
  font-size: 45px;
  text-align: center;
  text-transform: uppercase;

  @media (max-height: ${mediaQueries.desktop}px) {
    font-size: 42px;
  }

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    font-size: 32px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 32px;
  }

  @media (max-width: ${mediaQueries.smallPhones}px) {
    margin-top: 30px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;

const Location = styled.p`
  font-family: "AFT", sans-serif;
  font-size: 45px;
  text-align: center;
  text-transform: uppercase;

  @media (max-height: ${mediaQueries.desktop}px) {
    font-size: 42px;
  }

  @media (max-width: ${mediaQueries.tabletLandscape}px) {
    font-size: 32px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 32px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    font-size: 24px;
  }
`;

const WorkshopWrapper = styled.div`
  grid-area: workshop;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;

  @media (max-width: ${mediaQueries.tablet}px) {
    margin-top: 40px;
  }

  @media (max-width: ${mediaQueries.smallestPhones}px) {
    margin-top: 15px;
  }
`;

const Footer = styled.div`
  display: flex;
  grid-area: footer;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 30px;

  @media (max-height: ${mediaQueries.desktop}px) {
    padding: 15px 20px;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 15px;
  }
`;

const Legal = styled.p`
  font-family: "Neue Haas Grotesk";
  color: #898989;
  text-transform: uppercase;
  font-size: 14px;

  @media (max-width: ${mediaQueries.tablet}px) {
    font-size: 10px;
  }
`;

const FooterLogos = styled.div``;

const FooterLink = styled.a`
  &:first-child {
    margin-right: 25px;
  }
`;

const FooterLogo = styled.img`
  width: 40px;
  transition: 0.25s opacity ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mediaQueries.tablet}px) {
    margin-bottom: 20px;
    width: 50px;
  }
`;

const Alyx = () => {
  const { width, height } = useWindowSize();

  const updateMouse = e => {
    let x = (e.clientX / width) * 2 - 1;
    let y = (e.clientY / height) * 2 - 1;

    TweenMax.to("#buckle", 1, { rotationY: x * 30, rotationX: y * -30 });
  };

  useEffect(() => {
    TweenMax.to("#alyxContainer", 1.25, { autoAlpha: 1 });
  }, []);

  return (
    <>
      <GlobalStyle />
      <SEO title="HXOUSE · ALYX" />
      <BounceBackground />
      <Background
        onMouseMove={
          width > 800
            ? e => {
                updateMouse(e);
              }
            : undefined
        }
      >
        <ContentContainer id="alyxContainer">
          <TopBar>
            <BackLink href="https://www.hxouse.com/events/">
              BACK TO HXOUSE.COM
            </BackLink>
          </TopBar>
          <Container>
            <Wrapper>
              <PanelWrapper>
                <Subheader>PANEL DISCUSSIONS</Subheader>
                <Events>
                  {copy.alyxEvents.panels.map((event, i) => {
                    return (
                      <EventContainer
                        key={`${event.time}${i}`}
                        eventType="panel"
                      >
                        <EventTitleRSVP>
                          <EventTitleDate>
                            <EventText>{event.time}</EventText>
                            <EventText>{event.titleText}</EventText>
                          </EventTitleDate>
                          <EventButton href={event.link} target="_blank">
                            RSVP
                          </EventButton>
                        </EventTitleRSVP>
                        {event.additionalText.map((text, i) => {
                          return (
                            <EventText key={`${text}${i}`}>{text}</EventText>
                          );
                        })}
                      </EventContainer>
                    );
                  })}
                </Events>
              </PanelWrapper>
              <MainWrapper>
                <Header>1017 A L Y X 9SM</Header>
                <Header>GROWING FAMILY</Header>
                <PresentedWrapper>
                  <PresentedByText>PRESENTED BY</PresentedByText>
                  <HXOUSEImg src={HXOUSETypeface} />
                </PresentedWrapper>

                <BuckleWrapper>
                  <Buckle id="buckle" src={BuckleImg} />
                </BuckleWrapper>

                <FootnoteWrapper>
                  <FootnoteImg src={AlyxTypefaceImg} alt="ALYX logo" />
                </FootnoteWrapper>

                <Date>OCTOBER 20/21 2019</Date>
                <Location>130 QUEENS QUAY E.</Location>
              </MainWrapper>
              <WorkshopWrapper>
                <Subheader>WORKSHOP SESSIONS</Subheader>
                <Events>
                  {copy.alyxEvents.workshops.map((event, i) => {
                    return (
                      <EventContainer
                        key={`${event.time}${i}`}
                        eventType="workshop"
                      >
                        <EventTitleRSVP>
                          <EventTitleDate>
                            <EventText>{event.time}</EventText>
                            <EventText>{event.titleText}</EventText>
                          </EventTitleDate>
                          <EventButton href={event.link} target="_blank">
                            RSVP
                          </EventButton>
                        </EventTitleRSVP>
                      </EventContainer>
                    );
                  })}
                </Events>
              </WorkshopWrapper>
            </Wrapper>
          </Container>
          <Footer>
            <Legal>© HXOUSE 2019. ALL RIGHTS RESERVED.</Legal>
            <FooterLogos>
              <FooterLink target="_blank" href="https://alyxstudio.com">
                <FooterLogo src={AlyxFooterImg} alt="ALYX logo"></FooterLogo>
              </FooterLink>
              <FooterLink target="_blank" href="https://hxouse.com">
                <FooterLogo
                  src={HXOUSEFooterImg}
                  alt="HXOUSE logo"
                ></FooterLogo>
              </FooterLink>
            </FooterLogos>
          </Footer>
        </ContentContainer>
      </Background>
    </>
  );
};

export default Alyx;
